import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => { 
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/apparentConsumption').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.HOME}_${MODULE_NAMES.APPARENT_CONSUMPTION}`, reducer: reducer.default})
        import('./modules/intenationalvsRetailPrices').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.HOME}_${MODULE_NAMES.INTERNATIONAL_VS_RETAIL_PRICES}`, reducer: reducer.default})
          import('./modules/importsByProduct').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.HOME}_${MODULE_NAMES.IMPORTS_BY_PRODUCT}`, reducer: reducer.default})
            import('./modules/evolutionOfSubsidyPolicies').then(reducer => {
              injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.HOME}_${MODULE_NAMES.EVOLUTION_OF_SUBSIDY_POLICIES}`, reducer: reducer.default})
              import('./modules/watchIndicatorStatus').then(reducer => {
                injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.HOME}_${MODULE_NAMES.WATCH_INDICATORS_STATUS}`, reducer: reducer.default})
                import('./layouts/mainLayout').then(view => {
                  resolve(view);
                });
              });
            });
          });
        });
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute

