import React from 'react'
import {Route} from 'react-router'
import HomeRoute from './home'
import PriceRoute from './price'
import UseRoute from './use'
import AvailabilityRoute from './availability'
import PolicyRoute from './policy'
import WpContentPage from "../layouts/wpContentPage";
import ExportRoute from "./export";

const createRoute = (props) => {
  const {store} = props
  return (
    <div>
      <Route path="/kenya/home" render={({ match }) => <HomeRoute store={store} params={match.params}/>}/>
      <Route path="/kenya/price" render={({ match }) => <PriceRoute store={store} params={match.params}/>}/>
      <Route path="/kenya/use" render={({ match }) => <UseRoute store={store} params={match.params}/>}/>
      <Route path="/kenya/export" render={({ match }) => <ExportRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/kenya/availability" render={({ match }) => <AvailabilityRoute store={store} params={match.params}/>}/>
      <Route path="/kenya/policy" render={({ match }) => <PolicyRoute store={store} params={match.params}/>}/>
      <Route path="/kenya/wpContentPage/:slug" render={({ match }) => <WpContentPage store={store} params={match.params}/>}/>
  </div>
  )
}

export default createRoute
