import Immutable from 'immutable';
import ApiUtils from "../utils/apiUtils";
import {WP_ROOT_URL} from "../utils/apiUtils";

// ------------------------------------ Constants ------------------------------------
const CHANGE_SELECTED_LANGUAGE = 'CHANGE_SELECTED_LANGUAGE';
const CHANGE_PRINT_FORMAT = 'CHANGE_PRINT_FORMAT';

const LOAD_DATA_REQUEST = 'LOAD_DATA_REQUEST';
const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS';
const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE';
const SET_CONFIG_COUNTRY = 'SET_CONFIG_COUNTRY';
const WP_CATEGORY_NAME = 'focus-country-content-pages';

const endpointMapping = {
  translations: '/api/dashboardTranslation/all',
  chartConfigurations: '/api/configuration/chart/allMin',
  noDataText: '/api/settings/noData',
  argusLegend: '/api/fob/argusLegend',
  wpMenu: '/wp-json/menus/v1/menus/main?lang=',
  wpPages: '/wp-json/wp/v2/pages?per_page=100&lang=',
  wpCategory: '/wp-json/wp/v2/categories',
  dashboardData: '/api/dashboard'
}

// ------------------------------------ Actions ------------------------------------
export const changeSelectedLanguage = (selectedLanguage) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CHANGE_SELECTED_LANGUAGE, selectedLanguage});
  }
}

export const changePrintFormat = (format) => {
  return (dispatch, getState) => {
    dispatch({ 'type': CHANGE_PRINT_FORMAT, format});
  }
}

export const loadNoDataText = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    loadData('noDataText').then(data => {
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'noDataText'});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'noDataText'});
    })
  }
}

export const loadTranslationData = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    loadData('translations').then(data => {
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'translationData'});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'translationData'});
    })
  }
}

export const loadChartConfigData = (country) => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    loadData('chartConfigurations', country).then(data => {
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'chartConfigData'});
      dispatch({ 'type': SET_CONFIG_COUNTRY, country});
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'chartConfigData'});
    })
  }
}

export const loadArgusLegend = () => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    loadData('argusLegend').then(data => {
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'argusLegend' });
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'argusLegend' });
    })
  }
}

export const loadWPMenu = (lang) => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    const selectedLanguage = lang || getState().getIn(['main', 'selectedLanguage']);
    loadData('wpMenu', null, selectedLanguage, true).then(data => {
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'wpMenu' });
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'wpMenu' });
    })
  }
}

export const loadWPPages = (lang, country) => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    const selectedLanguage = lang || getState().getIn(['main', 'selectedLanguage']);
    loadData('wpCategory', null, selectedLanguage, true, {per_page: 100}).then(catData => {
      const parentCategory = catData.find(c => c.slug === WP_CATEGORY_NAME);
      const countryCategory = catData.find(c => c.slug === `${country}-${WP_CATEGORY_NAME}` && c.parent === parentCategory.id);
      dispatch({ 'type': LOAD_DATA_SUCCESS, catData, dataType: 'wpCategory' });
      if (countryCategory) {
        const extraParams = {categories: countryCategory.id, order: 'asc', orderby: 'menu_order'};
        loadData('wpPages', null, selectedLanguage, true, extraParams).then(data => {
          dispatch({'type': LOAD_DATA_SUCCESS, data, dataType: 'wpPages'});
        }).catch(function (err) {
          dispatch({'type': LOAD_DATA_FAILURE, err, dataType: 'wpPages'});
        })
      }
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'wpCategory' });
    })
  }
}

export const loadDashboardData = (countryIso) => {
  return (dispatch, getState) => {
    dispatch({ 'type': LOAD_DATA_REQUEST });
    loadData('dashboardData', countryIso, 'en').then(data => {
      dispatch(changeSelectedLanguage(data.defaultLanguage.locale))
      dispatch({ 'type': LOAD_DATA_SUCCESS, data, dataType: 'dashboardData' });
    }).catch(function(err) {
      dispatch({ 'type': LOAD_DATA_FAILURE, err, dataType: 'dashboardData' });
    })
  }
}

const loadData = (dataType, countryIso = 'GH', lang = 'en', useWPurl, extraParams) => {
  return new Promise((resolve, reject) => {
    ApiUtils.getData(endpointMapping[dataType], {countryIso, lang, ...extraParams}, {}, useWPurl ? WP_ROOT_URL : null)
      .then(
        function(data) {
          resolve(data);
        }
      )
      .catch(function(err) {
        reject(err);
      });
  })
}

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [CHANGE_SELECTED_LANGUAGE]: (state, action) => {
    return state.setIn(['selectedLanguage'], action.selectedLanguage);
  },
  [CHANGE_PRINT_FORMAT]: (state, action) => {
    return state.setIn(['printFormat'], action.format);
  },
  [LOAD_DATA_REQUEST]: (state, action) => {
    const {dataType} = action;
    return state.setIn([dataType, 'loading'], true);
  },
  [LOAD_DATA_SUCCESS]: (state, action) => {
    const {data, dataType} = action;
    return state.setIn([dataType, 'data'], data)
      .setIn([dataType, 'loading'], false).setIn([dataType, 'loaded'], true);
  },
  [LOAD_DATA_FAILURE]: (state, action) => {
    const {err, dataType} = action;
    return state.setIn([dataType, 'loading'], false).setIn(['loadingError'], err);
  },
  [SET_CONFIG_COUNTRY]: (state, action) => {
    return state.setIn(['configCountry'], action.country);
  }
};

// ------------------------------------ Reducer ------------------------------------
const initialState = Immutable.fromJS({
  configCountry: '',
  printFormat: 'png',
  selectedLanguage: 'en',
  translationData: {
    loaded: false,
    loading: false,
    data: {
      defaultLanguage: 'en',
      translations: {}
    }
  },
  chartConfigData: {
    loaded: false,
    loading: false,
    data: []
  },
  noDataText: {
    loaded: false,
    loading: false,
    data: {}
  },
  argusLegend: {
    loaded: false,
    loading: false,
    data: {}
  },
  wpMenu: {
    loaded: false,
    loading: false,
    data: {}
  },
  wpPages: {
    loaded: false,
    loading: false,
    data: {}
  },
  wpCategory: {
    loaded: false,
    loading: false,
    data: {}
  },
  dashboardData: {
    loaded: false,
    loading: false,
    data: {}
  }
});

// reducer is returned as default
export default function appReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
