import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";


const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('../policy/modules/governmentContribution').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.MALAWI}_${PAGES.POLICY}_${MODULE_NAMES.GOVERNMENT_CONTRIBUTION}`, reducer: reducer.default})
        import('./layouts/mainLayout').then(view => {
          resolve(view);
        });
      })
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute

