import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/importsByProduct').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.IMPORTS_BY_PRODUCT}`, reducer: reducer.default})
        import('./modules/retailPrices').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.RETAIL_PRICES}`, reducer: reducer.default})
          import('./modules/apparentConsumption').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.APPARENT_CONSUMPTION}`, reducer: reducer.default})
            import('./modules/plantDirectory').then(reducer => {
              injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.PLANT_DIRECTORY}`, reducer: reducer.default})
              import('./modules/governmentContribution').then(reducer => {
                injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.GOVERNMENT_CONTRIBUTION}`, reducer: reducer.default})
                import('./modules/watchIndicatorStatus').then(reducer => {
                  injectReducer({key: `${VIFAA_COUNTRIES.ETHIOPIA}_${PAGES.HOME}_${MODULE_NAMES.WATCH_INDICATORS_STATUS}`, reducer: reducer.default})
                  import('./layouts/mainLayout').then(view => {
                    resolve(view);
                  });
                });
              });
            });
          });
        });
      });  
    })
  })
  return (<HomeComponent {...props}></HomeComponent>)
}

export default createRoute
