import React from 'react'
import {Route} from 'react-router'
import HomeRoute from './home'
import PriceRoute from './price'
import UseRoute from './use'
import AvailabilityRoute from './availability'
import AboutRoute from './about'
import HelpRoute from './help'
import DatasetsRoute from './datasets'
import LicenseRoute from './license'
import DirectoryRoute from "./directory";
import PolicyRoute from "./policy";
import WpContentPage from "../layouts/wpContentPage";
import ExportRoute from "./export";


const createRoute = (props) => {
  const {store, history} = props
  return (
    <div>
      <Route path="/malawi/home" render={({ match }) => <HomeRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/price" render={({ match }) => <PriceRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/use" render={({ match }) => <UseRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/availability" render={({ match }) => <AvailabilityRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/about" render={({ match }) => <AboutRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/datasets" render={({ match }) => <DatasetsRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/export" render={({ match }) => <ExportRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/directory/:id" render={({ match }) => <DirectoryRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/directory" exact render={({ match }) => <DirectoryRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/policy" exact render={({ match }) => <PolicyRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/help" render={({ match }) => <HelpRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/license" render={({ match }) => <LicenseRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi/wpContentPage/:slug" render={({ match }) => <WpContentPage store={store} params={match.params}/>}/>
  </div>
  )
}

export default createRoute
