import React from 'react'
import {asyncComponent} from 'react-async-component'
import Routes from './routes'
import {injectReducer} from "../../../../store/store";

const createRoute = (props) => {
  const GhanaMainComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('../../modules/mainModule').then(reducer => {
        injectReducer({key: 'main', reducer: reducer.default}) // inject reducer
        import('./layouts/mainLayout').then(view => {
          resolve(view);
        });
      });
    })
  })

  return (
    <GhanaMainComponent>
      <Routes {...props}/>
    </GhanaMainComponent>
  )
}

export default createRoute
