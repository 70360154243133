import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router/immutable'
import thunk from 'redux-thunk'
import {createHashHistory} from 'history'
import { combineReducers } from 'redux-immutable'
import Immutable from 'immutable';
import { connectRouter } from 'connected-react-router/immutable'
// ---------------------------------

export const history = createHashHistory()
const initialState =Immutable.Map({})
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)


export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    ...asyncReducers
  })
}

export const store = createStore(
  makeRootReducer(),
  initialState,
  composedEnhancers
)


store.asyncReducers = {};
//store.unsubscribeHistory = hashHistory.listen(updateLocation(store))


export const injectReducer = ({ key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
  store.dispatch({type:'NONE'})
}


export default store
