import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {changeSelectedLanguage} from '../../../modules/mainModule';

class WpContentPage extends React.Component {

  render() {
    const {wpPages, params} = this.props;
    const {slug} = params;
    const page = wpPages.get('data').find(p => p.slug === slug);
    if (page) {
      return (
        <div className="wp-content-page">
          {/*<h1>{page.title && page.title.rendered}</h1>*/}
          {page.content && <div dangerouslySetInnerHTML={{__html: page.content.rendered}}/>}
        </div>
      )
    } else {
      return null;
    }
  }
}


const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeSelectedLanguage
}, dispatch)

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.getIn(['main', 'selectedLanguage']),
    translationData: state.getIn(['main', 'translationData']).toJS().data,
    chartConfigData: state.getIn(['main', 'chartConfigData']),
    wpPages: state.getIn(['main', 'wpPages'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WpContentPage);
