import React from 'react'
import Routes from './routes'
import {injectReducer} from '../../store/store'
import {asyncComponent} from 'react-async-component'
import {Route} from 'react-router';

const MainComponent = asyncComponent({
  resolve: () => new Promise(resolve => {
    import('./modules/mainModule').then(reducer => {
          injectReducer({key: 'main', reducer: reducer.default}) // inject reducer
      import('./layouts/mainLayout').then(view => {
        resolve(view);
      });
    });    
  })
})

const createRoute = (props) => {
  return (
    <div className="main-route">
      <Route path="/main" component={MainComponent}/>
      <Routes {...props}/>
    </div>
  )
}

export default createRoute
