import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => {
  const plantId = props.params.id;
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/plantDirectory').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.DIRECTORY}_${MODULE_NAMES.PLANT_DIRECTORY}`, reducer: reducer.default}) // inject reducer
          import('./layouts/mainLayout').then(view => {
            resolve(view);
          });
        });
    })
  })
  return (<HomeComponent plantId={plantId}></HomeComponent>)
}

export default createRoute
