import React from 'react'
import {asyncComponent} from 'react-async-component'

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
        import('./layouts/mainLayout').then(view => {
        resolve(view);
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute
