import React from 'react';
import {Route} from 'react-router';
import KenyaRoute from './kenya';
import NigeriaRoute from './nigeria';
import GhanaRoute from './ghana';
import SenegalRoute from './senegal';
import ZambiaRoute from './zambia';
import MalawiRoute from './malawi';
import EthiopiaRoute from './ethiopia';
import MozambiqueRoute from './mozambique';

const createRoute = (props) => {
  const {store, history} = props
  return (
    <div>
      <Route path="/kenya" render={({ match }) => <KenyaRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria" render={({ match }) => <NigeriaRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/ghana" render={({ match }) => <GhanaRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/senegal" render={({ match }) => <SenegalRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/zambia" render={({ match }) => <ZambiaRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/malawi" render={({ match }) => <MalawiRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/ethiopia" render={({ match }) => <EthiopiaRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/mozambique" render={({ match }) => <MozambiqueRoute store={store} history={history} params={match.params}/>}/>
    </div>
  )
}

export default createRoute
