import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/annualSubsidizedImports').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.POLICY}_${MODULE_NAMES.ANNUAL_SUBSIDIZED_IMPORTS}`, reducer: reducer.default})
        import('./modules/annualSubsidyCoverage').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.POLICY}_${MODULE_NAMES.ANNUAL_SUBSIDY_COVERAGE}`, reducer: reducer.default})
          import('./modules/evolutionOfSubsidyPolicies').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.POLICY}_${MODULE_NAMES.EVOLUTION_OF_SUBSIDY_POLICIES}`, reducer: reducer.default})
            import('./layouts/mainLayout').then(view => {
              resolve(view);
            });
          });
        });
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute
