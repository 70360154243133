import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => {  
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/cbu').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.PRICE}_${MODULE_NAMES.COST_BUILD_UP}`, reducer: reducer.default})
        import('./modules/retailPrices').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.PRICE}_${MODULE_NAMES.RETAIL_PRICES}`, reducer: reducer.default})
          import('./modules/retailPricesOverTime').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.PRICE}_${MODULE_NAMES.RETAIL_PRICES_OVER_TIME}`, reducer: reducer.default})
            import('./modules/fobPrices').then(reducer => {
              injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.PRICE}_${MODULE_NAMES.FOB_PRICES}`, reducer: reducer.default})
              import('./modules/intenationalvsRetailPrices').then(reducer => {
                injectReducer({key: `${VIFAA_COUNTRIES.KENYA}_${PAGES.PRICE}_${MODULE_NAMES.INTERNATIONAL_VS_RETAIL_PRICES}`, reducer: reducer.default})
                import('./layouts/mainLayout').then(view => {
                  resolve(view);
                });
              });  
            });            
          });          
        });
      });      
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute

