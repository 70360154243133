import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";
import {VIFAA_COUNTRIES} from "../../../../utils/constants";

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/importsByProduct').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.AVAILABILITY}_${MODULE_NAMES.IMPORTS_BY_PRODUCT}`, reducer: reducer.default}) // inject reducer
        import('./modules/importsByProductType').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.AVAILABILITY}_${MODULE_NAMES.IMPORTS_BY_PRODUCT_TYPE}`, reducer: reducer.default})
          import('./modules/importsByYear').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.AVAILABILITY}_${MODULE_NAMES.IMPORTS_BY_YEAR}`, reducer: reducer.default}) // inject reducer
          import('./modules/importsByCountryAndYear').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.AVAILABILITY}_${MODULE_NAMES.IMPORTS_BY_COUNTRY_AND_YEAR}`, reducer: reducer.default}) // inject reducer
            import('./modules/importsByCountryAndProduct').then(reducer => {
              injectReducer({
                key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.AVAILABILITY}_${MODULE_NAMES.IMPORTS_BY_COUNTRY_AND_PRODUCT}`,
                reducer: reducer.default
              }) // inject reducer
              import('./layouts/mainLayout').then(view => {
                resolve(view);
              });
            });
            });
          });
        });
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute

