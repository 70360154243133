import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {VIFAA_COUNTRIES} from "../../../../utils/constants";
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/exportsByYear').then(reducer => {
        injectReducer({
          key: `${VIFAA_COUNTRIES.MALAWI}_${PAGES.EXPORT}_${MODULE_NAMES.EXPORTS_BY_YEAR}`, reducer: reducer.default
        })
        import('./modules/exportsByCountry').then(reducer => {
          injectReducer({
            key: `${VIFAA_COUNTRIES.MALAWI}_${PAGES.EXPORT}_${MODULE_NAMES.EXPORTS_BY_COUNTRY}`, reducer: reducer.default
          })
          import('./layouts/mainLayout').then(view => {
            resolve(view);
          });
        });
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute
